.header {
  margin-top: 50px;
}

.list_header {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;

  h4 {
    margin: 10px;
  }
}

.lists_container {
  display: flex;
}

.list_wrapper {
  margin-right: 50px;
  width: 600px;
}

.button_wrapper {
  width: 400px;
  margin: 10px auto 0;
}

.list_body {
  height: 420px;
  overflow-y: scroll;
}

.icon_labels {
  display: flex;
  justify-content: space-between;
  width: 215px;
}

.searchRow {
  display: flex;
  width: 500px;
  gap: 10px;
  align-items: flex-end;
  & > * {
    flex: 1;
  }
}

.searchResult {
  height: 300px;
  overflow-y: scroll;
  max-width: 500px;
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
