@import './Colors.module.scss';

.row {
  display: flex;
  flex-wrap: wrap;
}

.rowCentered {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.textBlue {
  color: $blue;
}
