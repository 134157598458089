.box {
  margin-top: 30px;
  width: 700px;
}

.header {
  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.button_wrapper {
  align-self: flex-end;
  width: 160px;
}

.tertiary_first {
  margin-top: 30px;
  text-align: center;
}

.tertiary_second {
  text-align: center;
  margin: 20px;
}
