@import './Colors.module.scss';

%title {
  color: $blue;
  font-size: 30px;
}

%subtitle {
  color: $blue;
  font-size: 20px;
}