.box {
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  width: 430px;
}

.id_container {
  display: flex;
}

.id_wrapper {
  width: 330px;
}

.button_wrapper {
  width: 90px;
  margin-top: 46px;
  margin-left: 10px;
}
