.unlink {
  height: 25px;
  cursor: pointer;
}

.record_wrapper {
  margin: 5px;
  font-size: 18px;
  line-height: 32px;
}

.record {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}

.icons {
  display: flex;
  margin: 5px;
  width: 160px;
  justify-content: space-between;
}
