.tooltip_wrapper {
  display: flex;
  justify-content: space-between;

  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  border-color: #dfdfdf;
  border-width: 3px;
  border-style: solid;
  line-height: 30px;
}

.tooltip_column {
  margin: 8px;
}
