.container {
  width: 650px;
}

.button_wrapper {
  width: 350px;
}

.image {
  width: 550px;
}

.date {
  font-size: 20px;
  margin-top: 5px;
}
