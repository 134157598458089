.box {
  margin-top: 20px;
}

.text {
  font-size: 16px;
  margin-bottom: 70px;
}

.button_container {
  display: flex;
  justify-content: space-between;
}

.countdown_wrapper {
  width: 200px;
  line-height: 125px;
}

.button_wrapper {
  width: 130px;
}
