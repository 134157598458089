.navigationBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  max-width: 500px;
}

.page {
  width: 200px;
  margin-left: 10px;
}
