.drop {
  width: 100%;
  height: 100px;
  border: 2px dashed #c9c9c9;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-direction: column;
  cursor: pointer;
}

.separator {
  margin-top: 20px;
}

.legend {
  border: 1px dashed black;
  margin-top: 20px;
  padding: 10px;
  border-radius: 15px;
  > * {
    margin-bottom: 10px;
  }
}
