.page {
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
  > :first-child {
    margin-bottom: 20px;
    width: min-content !important;
  }
  > p {
    max-width: 1000px;
  }
}
