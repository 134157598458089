.box {
  display: flex;
}

.row {
  display: flex;
  flex-direction: row;
  width: 480px;
  justify-content: space-between;
  margin-bottom: 60px;
}
