.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.label {
  font-size: 32px;
  font-weight: bold;
  color: #60a5fa;
}
