.label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }