.page {
  width: 800px;
}

.main {
  display: flex;
  flex-direction: row;

  & > div:first-child {
    width: 500px;
  }
  & > div:last-of-type {
    width: 200px;
    padding: 20px;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 50px;
}

.submit {
  padding: 100px 0 0 0;
}

.row_field_power {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  width: 500px;

  & > :last-child {
    margin-bottom: 10px;
  }
}

.addPower {
  width: 500px;
  padding: 20px 0;
}

.searchRow {
  display: flex;
  width: 500px;
  gap: 10px;
  align-items: flex-end;
  & > * {
    flex: 1;
  }
}

.resultGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.searchResult {
  height: 300px;
  overflow-y: scroll;
  max-width: 500px;
  flex: 1;
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;

  & ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 50px;
  }

  & div {
    padding: 0 0 5px 0;
  }
}

.drugRemoveRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 5px;
  > span {
    margin: 5px 0 5px 10px;
  }
}
