.box {
  display: flex;
}

.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.column {
  flex: 1;

  & * {
    font-size: 15px !important;
  }

  & input {
    height: 30px;
  }

  & label {
    margin-top: 10px;
    margin-bottom: 1px;
  }
}
