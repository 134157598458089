.primaryButton {
  color: white;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  letter-spacing: 0.5px;
  width: 100%;
  height: 50px;
  border-width: 1px;
  border-style: solid;
}

.buttonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
