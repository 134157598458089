.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-height: 200px;
}

.element {
  padding: 15px;
}

.container {
  height: 470px;
}
