.container {
  min-height: 30px;
  display: flex;
  align-items: baseline;
  margin-top: 3px;
}

.label {
  margin-left: 10px;
  font-size: 17px;
  line-height: 30px;
}
