.box {
  max-width: 600px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 480px;
  justify-content: space-between;
  margin-top: 30px;
}
