.new_button_wrapper {
  max-width: 600px;
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 40px;
}

.card_container {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 15px;
}
