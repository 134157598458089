.header {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 40px;
}

.label {
  font-size: 18px;
  font-weight: 600;
}

.section {
  margin-top: 20px;
  margin-bottom: 20px;
}

.intervalButtons {
  display: flex;
  justify-content: space-between;
}

.intervalButton {
  width: 30%;
}

.days_container {
  width: 30%;
  margin: 20px auto;
}

.parity_container {
  width: 30%;
  margin: 20px 0 20px auto;
}

.radio_buttons {
  input {
    scale: 1.5;
  }

  label {
    font-size: 20px;
    margin-left: 10px;
    line-height: 30px;
  }
}

.dosage {
  width: 30%;
  display: flex;
  justify-content: space-between;
  input {
    width: 75px;
    text-align: center;
  }

  span {
    font-size: 50px;
    line-height: 60px;
    display: flex;
    align-items: end;
  }
}

input[type='number']::placeholder {
  color: white;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.full_wrapper {
  width: 30%;
}

.dosage_wrapper {
  width: 20vw;
}

.unit_wrapper {
  width: 6vw;
  margin-top: 16px;

  select {
    border: 1px solid #ccc;
    width: 6vw;
    height: 50px;
    margin-top: 8px;
    text-align: center;
    font-size: 17px;
  }

  select {
    // remove the arrow icon
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.dosage_header {
  margin-top: 35px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.errors {
  color: red;
}

.searchRow {
  display: flex;
  width: 532px;
  gap: 10px;
  align-items: flex-end;
  & > * {
    flex: 1;
  }
}

.searchResult {
  height: 300px;
  overflow-y: scroll;
  max-width: 532px;
  margin: 10px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;

  & ::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 300px;
    height: 50px;
  }
}

.drugItem {
  padding: 5px;
}

.powerItem {
  padding: 5px;
  border-radius: 17.5px;
  cursor: pointer;
}

.powerItem:hover {
  background-color: #b2d5ff;
}

.powerItemSelected {
  cursor: pointer;
  padding: 5px;
  border-radius: 17.5px;
  background-color: #60a5fa;
  color: white;
  font-weight: bold;
}
