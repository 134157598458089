.card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  min-width: 400px;
  margin: 14px;
}

.date {
  font-size: 18px;
  font-weight: 600;
  line-height: 56.5px;
}

.icon_button {
  margin-top: auto;
  margin-bottom: auto;
}
