.searchUserItem {
  border-radius: 17.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.searchUserItem:hover {
  background-color: #81f89a;
}

.itemName {
  padding: 10px;
}

.itemName {
  margin-left: 15px;
}

.add_icon {
  height: 25px;
  margin-right: 15px;
}
