.new_button_wrapper {
  max-width: 600px;
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.options {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  margin-left: 5px;
}

.result {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: scroll;
  height: 70vh;
}

.record {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  & > span {
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 1.1em;
  }
  & > div {
    padding-left: 20px;
    padding-top: 5px;
  }
}
