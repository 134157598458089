.modal_text {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 60px;
  font-size: 22px;
  font-weight: 400;
}

.buttons_container {
  display: flex;
  justify-content: space-between;

  button {
    width: 180px;
  }
}
