.navigationButton {
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border: none;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
  }
}
