.card {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  min-width: 300px;
}

.card_wrapper {
  padding: 15px;
  flex-direction: row;
}

.columns {
  display: flex;
  justify-content: space-between;
}

.buttons_container {
  margin-top: 15px;
}

.pills_name {
  color: #60a5fa;
  font-weight: 900;
  font-size: 18px;
}

.day_dosage {
  font-size: 18px;
}

.dosage {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 30px;
  font-weight: 400;
}
