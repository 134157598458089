.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  background-color: #c8c8c8;
  width: 100vw;
  height: 100%;
}

.modal_wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  min-width: 500px;
  background-color: white;
}
