.box {
  display: flex;
  justify-content: space-between;
}

.checkbox_form {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.buttons_container {
  display: flex;
  width: 130px;
  justify-content: space-between;
  padding-bottom: 10px;
}

.button {
  color: white;
  background-color: black;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  border: 1px solid black;
}

.table_header {
  min-width: 130px;
  text-align: center;
  padding: 0 28px;
}

.table_data {
  text-align: center;
}

.action_button {
  width: 200px;
  padding-left: 20px;
  padding-right: 20px;
}

.table_wrapper {
  width: 1250px;
  table {
    justify-content: space-between;
  }
}

.dna_wrapper {
  text-align: center;
}
