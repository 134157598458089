.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.label {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.input {
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  padding: 0.8rem;
  width: 100%;
  height: 50px;
}

.textarea {
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  padding: 12.8px;
  resize: none;
  border: 1px solid #ccc;
}

.phone_container {
  display: flex;
}

.phone_prefix {
  font-size: 18px;
  margin-right: 15px;
  line-height: 50px;
}

.letterCounter {
  text-align: right;
}

.text_view_wrapper {
  width: 100%;
  position: relative;
  margin-top: 10px;
}

.text_area_container {
  position: relative;
}

.textarea {
  width: 100%;
}

.letterCounter {
  position: absolute;
  bottom: -17px; // Position from bottom of the textarea
  right: 5px; // Position from right of the textarea
  padding: 2px 5px;
  font-size: smaller;
  color: #333; // Choose a color that suits your design
}

.unit {
  position: absolute;
  right: 10px;
  bottom: 3px;
}
