.navbar {
    width: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px 0 rgba(0,0,0, .25);
}

.logo {
    display: flex;
    justify-content: center;
    padding: 20px 0 30px 0;
}

.buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
    gap: 20px;
    flex: 1;
}

.bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 10px
}