.frame {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 10px 20px;
  overflow: scroll;
  max-width: 2000px;
}

.center {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
