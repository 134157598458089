.iconButton {
  border-radius: 10px;
  background-color: #fff;
  display: block;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  border: none;
  width: 210px;
  height: 170px;
}

.subtext {
  margin-top: 10px;
  color: white;
  font-size: 20px;
}
