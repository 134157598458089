.wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
}

.label {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 0.9rem;
  margin-top: 1rem;
  text-align: center;
}

.input {
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 2rem;
  padding: 0.8rem;
  height: 70px;
  text-align: center;
  letter-spacing: 15px;
}

.input::placeholder {
  letter-spacing: 1px;
  font-size: 1.9rem;
}
