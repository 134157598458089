.box {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  margin-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  height: 300px;
}

.button_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  width: 100%;
  & > button {
    flex: 1;
  }
}
