.table_header {
  min-width: 130px;
}

.table_data {
  text-align: center;
}

.action_button {
  width: 200px;
  padding-left: 20px;
  padding-right: 20px;
}

.table_wrapper {
  width: 1250px;
  table {
    justify-content: space-between;
  }
}

.dna_wrapper {
  text-align: center;
}
