@import '../../styles/Font.module.scss';

.header {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
}

.title {
  @extend %title;
}

.subtitle {
  @extend %subtitle;
}

.right_wrapper {
  height: 100%;
  display: flex;
}
