.card {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  width: 350px;
}

.row_container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.name_title {
  color: #60a5fa;
  font-weight: bold;
  font-size: 18px;
}

.title {
  font-weight: bold;
  font-size: 18px;
}

.name_text {
  color: #60a5fa;
  font-size: 18px;
}

.text {
  font-size: 18px;
}

.comment_wrapper {
  margin-top: 10px;
}

.button_wrapper {
  margin: 40px auto 0px;
  width: 200px;
}
